import styled from "styled-components"
import { isMobile } from "react-device-detect"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-top: 4rem;
  ${isMobile &&
  `
    margin-bottom: 4rem;
    flex-direction: column;
    padding-bottom: 8rem;
    margin-left: 0;
  `}
`
export const Title = styled.h3`
  color: #fdfdfd;
  font-family: "Abril Fatface";
  font-size: 3.65rem;
  background-color: #020710;
  display: inline;
  padding: 0 1rem;
  padding-bottom: 0.6rem;
  box-decoration-break: clone;
  font-weight: 400;
  margin-left: -1rem;
  width: 70%;
`

export const SingleContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1024px) {
    width: 100vw;
  }
`

export const PhotoContainer = styled.img`
  margin-bottom: 1rem;
  width: 100%;
  height: 15rem;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 100vw;
    height: 21rem;
    object-fit: cover;
    margin: 2rem 0 2rem -7.5% ;
    display: block;
  }
`

export const SingleLink = styled.a`
  color: #2b2b2b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`

export const LookButton = styled.div`
  width: 96px;
  height: 31px;
  border-radius: 13px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-right: 3rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 1024px) {
    margin-right: 7rem;
  }
`