import React from "react"
import BodyWrap from "src/components/BodyWrap"
import { AnimatePresence, motion } from "framer-motion"
import H1 from "src/components/H1"
import OtherPortalsMozaik from "src/components/OtherPortalsMozaik"
import LogosBar from "src/components/LogosBar"

import MozaicImage1 from "src/images/chapter9/1.png"
import MozaicImage2 from "src/images/chapter9/2.png"
import MozaicImage3 from "src/images/chapter9/3.png"
import MozaicImage4 from "src/images/chapter9/4.png"
import MozaicImage5 from "src/images/chapter9/5.png"
import MozaicImage6 from "src/images/chapter9/6.png"

import Logo1 from "src/images/chapter9/logos/jedynka.png"
import Logo2 from "src/images/chapter9/logos/dwojka.png"
import Logo3 from "src/images/chapter9/logos/trojka.png"
import Logo4 from "src/images/chapter9/logos/czworka.png"
import Logo5 from "src/images/chapter9/logos/pr24.png"
import Logo6 from "src/images/chapter9/logos/radiodzieciom.png"

const Chapter9 = () => {
  const mozaicArray = [
    {photo: MozaicImage1, link: "https://www.polskieradio.pl/219,Serwisy-Specjalne-Polskiego-Radia"},
    {photo: MozaicImage2, link: "https://www.polskieradio.pl"},
    {photo: MozaicImage3, link: "https://www.polskieradio24.pl"},
    {photo: MozaicImage4, link: "https://podcasty.polskieradio.pl"},
    {photo: MozaicImage5, link: "https://www.facebook.com/PolskieRadio24pl"},
    {photo: MozaicImage6, link: "https://twitter.com/polskieradiopl"}
  ]

  const logos = [
    {photo: Logo1, link: "https://www.polskieradio.pl/7,Jedynka"},
    {photo: Logo2, link: "https://www.polskieradio.pl/8,Dwojka"},
    {photo: Logo3, link: "https://www.polskieradio.pl/9,Trojka"},
    {photo: Logo4, link: "https://www.polskieradio.pl/10,Czworka"},
    {photo: Logo5, link: "https://www.polskieradio24.pl"},
    {photo: Logo6, link: "https://www.polskieradio.pl/18,Dzieci"}
  ]

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <BodyWrap>
          <H1 />
          <OtherPortalsMozaik mozaicArray={mozaicArray}/>
          <LogosBar logos={logos}/>
        </BodyWrap>
      </motion.div>
    </AnimatePresence>
  )
}

export default Chapter9
