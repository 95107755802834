import React from "react"
import { Wrapper, SingleContainer, SingleLink, PhotoContainer} from "./styles"

const LogosBar = ({logos}) => {

  const singleElement = (photo, link) => {
    return (
      <SingleLink href={link} target="_blank">
        <PhotoContainer src={photo}/>
      </SingleLink>
    )
  }

  const allElements = logos.map( x =>{
    return (
      singleElement(x.photo, x.link)
    )
  })
  
  return (
    <>
      <Wrapper>
        <SingleContainer>
          {allElements}
        </SingleContainer>
      </Wrapper>
    </>
  )
}

export default LogosBar
