import React from "react"
import SEO from "../components/seo"
import Chapter9 from "src/views/Chapter9"

const IndexPage = () => {
  return (
    <>
      <SEO title="Do usłyszenia, do zobaczenia" description="Pozostałe projekty Polskiego Radia" />
      <Chapter9 />
    </>
  )
}

export default IndexPage