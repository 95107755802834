import styled from "styled-components"

export const Text = styled.h1`
  opacity: 0.22;
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 3rem;
`
