import React, { useEffect, useState } from "react"
import Desktop from "./desktop"
import Mobile from "./mobile"
import { isMobile } from "react-device-detect"

const Chapter9 = () => {
  if (isMobile) return <Mobile />
  return <Desktop />
}

export default Chapter9
