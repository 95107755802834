import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  padding-left: 1rem;

  @media (max-width: 1024px) {
    padding-left: 0;
    width: 85%;
    margin: 0 auto;
    padding-top: 15rem;
  }
`
