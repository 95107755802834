import styled from "styled-components"
import { isMobile } from "react-device-detect"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
`

export const SingleContainer = styled.div`
  width: 530px;
  display: flex;
  justify-content: space-between;
`

export const PhotoContainer = styled.img`
  object-fit: cover;
  cursor: pointer;
  transform: scale(0.8);
`

export const SingleLink = styled.a`
  color: #2b2b2b;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`
