import React from "react"
import { Wrapper, SingleContainer, SingleLink, PhotoContainer, LookButton, Title } from "./styles"

const NextChapterLink = ({mozaicArray}) => {

  const singleElement = (photo, link) => {
    return (
      <SingleContainer>
        <SingleLink href={link} target="_blank">
          <PhotoContainer src={photo}/>
        </SingleLink>
        <SingleLink href={link} target="_blank">
          <LookButton>
            zobacz
          </LookButton>
        </SingleLink>

      </SingleContainer>
    )
  }

  const allElements = mozaicArray.map( x =>{
    return (
      singleElement(x.photo, x.link)
    )
  })
  
  return (
    <>
      <Title tabindex="0">Do usłyszenia, do zobaczenia</Title>
      <Wrapper>
        {allElements}
      </Wrapper>
    </>
  )
}

export default NextChapterLink
