import React, {useState, useEffect} from "react"
import { Wrapper } from "./styles"

const BodyWrap = ({ children }) => {
  const [focusEnabled, setFocusEnabled] = useState(false)

  function enableFocus({ key }) {
    if (key === "Tab") {    
      setFocusEnabled(true)                       
    }
  } 

  useEffect(() => {
    window.addEventListener("keyup", enableFocus)
    return () => {
      window.removeEventListener("keyup", enableFocus)
    }
  }, [])
  return <Wrapper className={focusEnabled? 'focusable':''}>{children}</Wrapper>
}

export default BodyWrap
